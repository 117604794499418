
export const state = () => ({
  vo: [
    {
      title: '¿Cuál es la diferencia de contratar un renting de ReDrive?',
      description: 'Todos los vehículos son de primera mano y han pasado el historial de servicio completo en línea con las directrices del fabricante. Además, son verificados por nuestros expertos antes de ser ofertados.',
    },
    {
      title: '¿Puedo ver el coche?',
      description: 'Si, puedes acercarte a las tiendas para ver el coche que te interesa, nuestros comerciales te atenderán y asesoraran.',
    },
    {
      title: '¿Si no me convence el coche puedo cambiarlo o devolverlo?',
      description: 'Puedes cancelar tu contrato sin penalización o cambiarlo a los seis meses. Todos los vehículos tienen una garantía de 15 días o 1.000 kilómetros (lo que antes suceda) a partir de la entrega. Dispones de este plazo para probar el coche y tomar una decisión.',
    },
    {
      title: '¿Quién puede conducir mi renting de ReDrive?',
      description: 'El titular del contrato y cualquier conductor deben de tener entre 25 y 70 años y más de dos años de carnet de conducir en vigor. Para poder autorizar a un conductor adicional, debes de informar previamente a Ayvens.',
    },
    {
      title: '¿Puedo viajar al extranjero con mi vehículo?',
      description: 'Si quieres viajar al extranjero con tu vehículo tienes que llamar a Atención al Cliente (913 336 717) para que te faciliten la documentación requerida por el país al que quieras viajar.',
    },
    {
      title: '¿Qué ocurre cuando finaliza mi contrato de renting?',
      description: 'Tu contrato de ReDrive finaliza a los 12 o 24 meses y tienes la opción de renovarlo por otro o comprarlo.'
    },
    {
      title: '¿Puedo contratar un ReDrive en Canarias, Baleares, Ceuta o Melilla?',
      description:  'El producto ReDrive, sólo está disponible para península y Baleares. Consúltanos las condiciones, importes y disponibilidad.'
    }
  ],
  voFull: [
    {
      title: '¿Cuál es la diferencia de contratar un renting de ReDrive?',
      description: 'Todos los vehículos son de primera mano y han pasado el historial de servicio completo en línea con las directrices del fabricante. Además, son verificados por nuestros expertos antes de ser ofertados.',
    },
    {
      title: '¿Puedo ver el coche?',
      description: 'Si, puedes acercarte a las tiendas para ver el coche que te interesa, nuestros comerciales te atenderán y asesoraran.',
    },
    {
      title: '¿De dónde proceden los vehículos de ReDrive?',
      description: 'Todos los vehículos proceden de nuestros contratos de renting ya finalizados, seleccionamos minuciosamente los mejores y son cuidadosamente revisados. Además, te ofrecemos la información detallada del historial del vehículo, para que tengas total confianza en su estado.',
    },
    {
      title: '¿Puedo hacer la contratación online?',
      description: 'La contratación se realiza online en cuatro sencillos pasos:\n' +
        '   <ul> <li>1. Elige tu coche</li>\n' +
        '    <li>2. Sube la documentación y será valorada</li>\n' +
        '    <li>3. Entrega</li>\n' +
        '    <li>4. Empieza a disfrutarlo</li></ul>',
    },
    {
      title: '¿Cuánto tiempo tarda Ayvens en entregarme el coche?',
      description: 'Una vez confirmado el pedido y firmado el contrato el coche estará disponible en un plazo de 30 días.',
    },
    {
      title: '¿Dónde me entregarán mi coche?',
      description: 'En la tienda donde este el vehículo. Si quieres que la entrega la realicen en otra provincia tienes que abonar el transporte del traslado del vehículo.',
    },
    {
      title: '¿Si no me convence el coche puedo cambiarlo o devolverlo?',
      description: 'Puedes cancelar tu contrato sin penalización o cambiarlo a los seis meses. Todos los vehículos tienen una garantía de 15 días o 1.000 kilómetros (lo que antes suceda) a partir de la entrega. Dispones de este plazo para probar el coche y tomar una decisión.',
    },
    {
      title: '¿Quién puede conducir mi renting de ReDrive?',
      description: 'El titular del contrato y cualquier conductor deben de tener entre 25 y 70 años y más de dos años de carnet de conducir en vigor. Para poder autorizar a un conductor adicional, debes de informar previamente a Ayvens.',
    },
    {
      title: '¿Puedo viajar al extranjero con mi vehículo?',
      description: 'Si quieres viajar al extranjero con tu vehículo tienes que llamar a Atención al Cliente (913 336 717) para que te faciliten la documentación requerida por el país al que quieras viajar.',
    },
    {
      title: '¿Cómo encuentro un taller que se ocupe del servicio y mantenimiento o reparaciones de daños?',
      description: 'Tienes que llamar a Atención al Cliente (913 336 717) y ellos te indicarán todos los talleres concertados con Ayvens que más cerca se encuentran de tu ubicación.',
    },
    {
      title: '¿Puedo devolver el vehículo con la ITV caducada?',
      description: 'Para el uso del vehículo es necesario tener la ITV en vigor. De lo contrario estas expuesto a una sanción.',
    },
    {
      title: '¿Cómo paso la ITV de mi renting de ReDrive?',
      description: 'Tienes que llamar a atención al cliente para que te faciliten el bono correspondiente a la ITV del vehículo. En el caso de que el vehículo no pase la ITV hay que avisar a Atención al Cliente (913 336 717) y Ayvens se hará cargo de las reparaciones necesarias para que el vehículo pueda pasar la ITV.',
    },
    {
      title: '¿Qué ocurre cuando finaliza mi contrato de renting?',
      description: 'Tu contrato de ReDrive finaliza a los 12 o 24 meses y tienes la opción de renovarlo por otro o comprarlo.'
    },
    {
      title: '¿Cómo tengo que devolver mi vehículo al finalizar el contrato?',
      description:  'Tienes que devolverlo limpio (tanto exterior como interior), con todos los elementos que se entregaron y vaciar tus objetos personales, para que los profesionales de Ayvens puedan evaluar el estado del vehículo.'
    },
    {
      title: '¿Puede ir cualquier persona a devolver el vehículo?',
      description:  'La devolución tiene que efectuarla el titular del contrato ya que se realiza un acta de devolución y una verificación del estado del vehículo.'
    },
    {
      title: '¿En qué momento tengo opción a comprar un coche de ReDrive?',
      description:  'Puedes comprar el vehículo a los 6, 12 o 24 meses (en función de tu contrato) y te haremos un descuento equivalente a 3 cuotas del renting.'
    },
    {
      title: '¿Puedo contratar un renting de Ayvens en Canarias, Baleares, Ceuta o Melilla?',
      description: 'El producto ReDrive, sólo está disponible para península y Baleares. Consúltanos las condiciones, importes y disponibilidad.'
    },
    {
      title: '¿Existen otros cargos además de la cuota mensual?',
      description: 'Junto con la primera cuota, se cobrará una cuota adicional en concepto de depósito de garantía, que será reembolsada al finalizar el contrato.'
    }
  ],
  vn: [
    {
      title: '¿Qué servicios incluye mi contrato de renting?',
      description: 'Incluye todo lo que necesitas para utilizar tu vehículo sin preocupaciones: el derecho de uso, las operaciones de mantenimiento, reparación de daños propios y a terceros, asistencia en carretera 24/7, gestión de multas y un servicio de atención al cliente siempre disponible para ti. Los mantenimientos preventivos, que son las revisiones periódicas establecidas por el fabricante, y los correctivos que son las reparaciones de las averías mecánicas. Todos los cambios de neumáticos necesarios para mantener la seguridad del vehículo y cumplir con la normativa vigente; debidos al desgaste normal por el uso, pinchazos, reventones y rajaduras.',
    },
    {
      title: '¿Puedo hacer la contratación online?',
      description: 'La contratación se realiza online en cuatro sencillos pasos:\n' +
        '   <ul> <li>1. Elige tu coche</li>\n' +
        '    <li>2. Sube la documentación y será valorada</li>\n' +
        '    <li>3. Entrega</li>\n' +
        '    <li>4. Empieza a disfrutarlo</li></ul>',
    },
    {
      title: '¿Cómo funciona el proceso de scoring financiero?',
      description: 'Ayvens dispone de herramientas que realizan una verificación automática de solvencia con tu entidad financiera. Una vez finalizado el proceso de verificación, la información es destruida.'
    },
    {
      title: '¿Dónde me entregarán mi coche?',
      description: 'Te entregarán el coche en el concesionario acordado.',
    },
    {
      title: '¿Puedo viajar al extranjero con mi vehículo?',
      description: 'Si quieres viajar al extranjero con tu vehículo tienes que llamar a Atención al Cliente (913 336 717) para que te faciliten la documentación requerida por el país al que quieras viajar.',
    },
    {
      title: '¿Qué ocurre cuando finaliza mi contrato de renting?',
      description: 'Al finalizar tu contrato de renting puedes solicitar una prórroga de este o una renovación por otro vehículo. Sino optas por alguna de las dos opciones anteriores tienes que devolver tu vehículo.',
    },
    {
      title: '¿Puedo contratar un renting de Ayvens en Canarias, Baleares, Ceuta o Melilla?',
      description: 'Por supuesto, entregamos vehículos fuera de la península. Consúltanos las condiciones, importes y disponibilidad.'
    }
  ],
  vnFull: [
    {
      title: '¿Qué servicios incluye mi contrato de renting?',
      description: 'Incluye todo lo que necesitas para utilizar tu vehículo sin preocupaciones: el derecho de uso, las operaciones de mantenimiento, reparación de daños propios y a terceros, asistencia en carretera 24/7, gestión de multas y un servicio de atención al cliente siempre disponible para ti. Los mantenimientos preventivos, que son las revisiones periódicas establecidas por el fabricante, y los correctivos que son las reparaciones de las averías mecánicas. Todos los cambios de neumáticos necesarios para mantener la seguridad del vehículo y cumplir con la normativa vigente; debidos al desgaste normal por el uso, pinchazos, reventones y rajaduras.',
    },
    {
      title: '¿Puedo hacer la contratación online?',
      description: 'La contratación se realiza online en cuatro sencillos pasos:\n' +
        '   <ul> <li>1. Elige tu coche</li>\n' +
        '    <li>2. Sube la documentación y será valorada</li>\n' +
        '    <li>3. Entrega</li>\n' +
        '    <li>4. Empieza a disfrutarlo</li></ul>',
    },
    {
      title: '¿Cómo funciona el proceso de scoring financiero?',
      description: 'Ayvens dispone de herramientas que realizan una verificación automática de solvencia con tu entidad financiera. Una vez finalizado el proceso de verificación, la información es destruida.'
    },
    {
      title: '¿Dónde me entregarán mi coche?',
      description: 'Te entregarán el coche en el concesionario acordado.',
    },
    {
      title: '¿Cuándo me entregarán la documentación del vehículo?',
      description: 'Cuando el vehículo esté listo, Ayvens te enviará un documento llamado “puesta de disposición”. Deberás concertar una cita con la concesión y entregar este documento para recoger tu coche. Toda la documentación irá en su interior.',
    },
    {
      title: '¿Puedo viajar al extranjero con mi vehículo?',
      description: 'Si quieres viajar al extranjero con tu vehículo tienes que llamar a Atención al Cliente (913 336 717) para que te faciliten la documentación requerida por el país al que quieras viajar.',
    },
    {
      title: '¿Cómo encuentro un taller que se ocupe del servicio y mantenimiento o reparaciones de daños?',
      description: 'Tienes que llamar a Atención al Cliente (913 336 717) y ellos te indicarán todos los talleres concertados con Ayvens que más cerca se encuentran de tu ubicación.',
    },
    {
      title: '¿Dispongo de servicio de recogida y entrega cuando tenga que llevar el coche al taller?',
      description: 'Si, este servicio está incluido, cuando tengas que llevar tu coche al taller, tendrás que contactar con Atención al Cliente en el número: 913 336 717 y ellos te gestionarán la recogida y entrega del vehículo. Este servicio no incluye la recogida y entrega en caso de cambio de neumáticos.',
    },
    {
      title: '¿En qué casos de inmovilización podré disponer de un vehículo de sustitución?',
      description: 'Siempre que tengas el servicio de vehículo adicional contratado.',
    },
    {
      title: '¿Es elevado el coste de un vehículo de sustitución?',
      description: 'Depende de cada oferta de Renting. El cliente abona sólo los días utilizados, o reservará diferentes paquetes de días a usar y los repartirá según su conveniencia.',
    },
    {
      title: '¿Cuáles son las coberturas del vehículo de sustitución?',
      description: 'Las coberturas son las mismas que las del vehículo habitual; el segmento del vehículo de sustitución puede variar con respecto al contratado por la disponibilidad del momento.',
    },
    {
      title: '¿Puede conducir cualquier persona el vehículo de sustitución?',
      description: 'El vehículo de sustitución tiene los mismos requisitos que el de renting.',
    },
    {
      title: '¿Puedo salir al extranjero con el vehículo de sustitución?',
      description: 'Tienes que consultar las condiciones de cesión del vehículo de sustitución que te han facilitado.',
    },
    {
      title: '¿Y si tengo un percance con el vehículo de sustitución y necesito una grúa?',
      description: 'Tendrás que ponerte en contacto con el departamento de siniestros de Ayvens (913 336 717) y ellos te indicarán como proceder.',
    },
    {
      title: '¿Qué ocurre cuando finaliza mi contrato de renting?',
      description: 'Al finalizar tu contrato de renting puedes solicitar una prórroga de este o una renovación por otro vehículo. Sino optas por alguna de las dos opciones anteriores tienes que devolver tu vehículo.',
    },
    {
      title: '¿Cómo tengo que devolver mi vehículo al finalizar el contrato?',
      description: 'Tienes que devolverlo limpio (tanto exterior como interior), con todos los elementos que se entregaron y vaciar tus objetos personales, para que los profesionales de Ayvens puedan evaluar el estado del vehículo.',
    },
    {
      title: '¿Puedo contratar un renting de Ayvens en Canarias, Baleares, Ceuta o Melilla?',
      description: 'Por supuesto, entregamos vehículos fuera de la península. Consúltanos las condiciones, importes y disponibilidad.'
    }

]
})

export const getters = {

}

export const mutations = {

}

export const actions = {

}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "px-6 py-6 lg:py-10 lg:px-20 main-p",
      style: { backgroundImage: `url(${_vm.backgroundImagePath})` },
    },
    [
      _c("section", { staticClass: "bg-center bg-cover lights" }, [
        _c("div", { staticClass: "lights-text" }, [
          _c("h2", {
            staticClass: "title",
            domProps: { innerHTML: _vm._s(_vm.title) },
          }),
          _vm._v(" "),
          _c("p", {
            staticClass: "my-4 sub-title",
            domProps: { innerHTML: _vm._s(_vm.subTitle) },
          }),
          _vm._v(" "),
          _vm._m(0),
          _vm._v(" "),
          _vm.formSubmitted
            ? _c(
                "div",
                { staticClass: "flex-col hidden thanks-message xl:flex" },
                [
                  _c("h3", { staticClass: "title" }, [
                    _vm._v("Gracias por tu interés"),
                  ]),
                  _vm._v(" "),
                  _c("p", { staticClass: "my-4 sub-title" }, [
                    _vm._v(
                      "\n          Hemos recibido tu solicitud. Un comercial\n          contactará contigo lo antes posible.\n        "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "form-box" },
          [
            _vm.type === "particulares" ||
            _vm.type === "empresas" ||
            _vm.formType === "both"
              ? _c("ContactUsGeneral", {
                  attrs: { type: _vm.type, from: "module", textWhite: true },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.type === "flex"
              ? _c("FlexContactUs", { attrs: { from: "flex" } })
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex py-2 buttons" }, [
      _c("a", { attrs: { href: "tel:910 554 913" } }, [
        _c("button", { staticClass: "button-contact" }, [
          _c("div", [
            _c("img", {
              staticClass: "phone-white",
              attrs: {
                width: "29",
                src: require("assets/images/icons/phone-simple-icon.svg"),
                alt: "",
              },
            }),
            _vm._v(" "),
            _c("img", {
              staticClass: "phone-dark",
              attrs: {
                width: "29",
                src: require("assets/images/icons/phone-simple-icon-dark.svg"),
                alt: "",
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "text" }, [
            _c("span", [_vm._v("Solicite una oferta")]),
            _vm._v(" "),
            _c("span", [_vm._v("910 554 913")]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }
import axios from "axios";
import nuxtConfig from "~/nuxt.config";

export const state = () => ({
  offers: {
  },
  bestOffers: [],
  lowerOffers: [],
  compare: [],
  session:"",
  loading: false,
  page: 1,
  meta: null,
  enough: false,
  results: {
    offers: [],
    pages: []
  },
  homeOffer: null,
  flexData: null,
  flexCode: null
})

export const getters = {
  isInCompare: (state) => (id) => {
    return state.compare.find(o => o.id === id)
  },
  isEmptyCompare:(state) => () =>{
    return state.compare.length === 0;
  },
  compares:(state) => state.compare

}

export const mutations = {
  setLoading(state,loading){
    state.loading = loading;
  },
  setResults(state, results){
    state.results = results;
  },
  setMeta(state,meta){
    state.offers.meta = meta;
  },
  setSession(state,session){
    state.session = session;
  },
  addToCompare(state, offer) {
    state.compare.push(offer)
  },
  removeFromCompare(state, offer) {
    state.compare = state.compare.filter(data => data.id !== offer.id);
  },
  setOffers(state, offers) {
    state.offers = offers;
  },
  setBestOffers(state, offers) {
    state.bestOffers = offers;
  },
  setLowerOffers(state, offers) {
    state.lowerOffers = offers;
  },
  setHomeOffer(state, offer) {
    state.homeOffer = offer;
  },
  setCompare(state,compare){
    state.compare = compare;
  },
  addOffer(state,offer){
    state.offers.data.push(offer);
  },
  setEnough(state, enough){
    state.offers.enough = enough;
  },
  setFlexData(state, data) {
     state.flexData = data;
  },
  setOffersData(state, data){
    data.forEach(function (item){
      if(state.offers.data.find(o => o.id === item.id)){
        return
      }
      state.offers.data.push( item)
    })
  },
  setFexCode(state, code){
      state.flexCode = code
  }
}

export const actions = {
  async getFlexData({ commit }, token) {
      await axios.get(`${nuxtConfig.axios.baseURL}/flex-data`, {
        headers: {
          'Authorization': 'Bearer ' +  token
        }
      }).then(res => {
        commit("setFlexData", res.data)
        return true
      })
        .catch(e => {
          console.error('error on get flex data', e);
        })
        .finally(() => commit("setLoading", false))

  },
  async addToCompare({ commit, state, dispatch }, offer) {
    if (state.compare.length === 3) {
      return 'full'
    }
    dispatch('addOfferToCompare',offer);
    commit("addToCompare", offer)
    return true;
  },
  setFlexCode({commit}, code){
     commit('setFexCode', code)
  },
  async removeFromCompare({ commit,state,dispatch }, offer) {
    dispatch('delOfferToCompare',offer);

    commit("removeFromCompare", offer)
    return true;
  },
  async getLowerOffers({ commit }) {
    commit("setLoading", true)
    const { data } = await axios.get(`${nuxtConfig.axios.baseURL}/lower_offers`, {
      headers: {
        'Authorization': 'Bearer ' + process.env.ACCESS_TOKEN
      }
    })
    .catch(e => {
      console.error('error on get lower offers', e);
    })
    .finally(() => commit("setLoading", false))

    commit("setLowerOffers", data)
  },
  async getBestOffers({ commit,state }, payload = {numItems: 3, type: null, status: null}) {
    commit("setLoading", true)
    let type = null != payload.type ? `&type=${payload.type}` : ''
    let status = null != payload.status ? `&status=${payload.status}` : ''
    await axios.get(nuxtConfig.axios.baseURL + '/offers/best?numItems=' + payload.numItems + type + status,
      {
        headers: {
          'Authorization': 'Bearer ' +  process.env.ACCESS_TOKEN
        }
      })
      .then(res => {
        commit("setBestOffers", res.data)
        console.log(res.data)
        commit("setLoading", false)
        return true
      }).catch(e => {
        console.log(e.message)
        commit("setLoading", false)
      })
  },
  async getHomeOffer({ commit }, payload = {numItems: 1, type: null, status: null}) {
    commit("setLoading", true)
    let type = null != payload.type ? `&type=${payload.type}` : ''
    let status = null != payload.status ? `&status=${payload.status}` : ''
    await axios.get(nuxtConfig.axios.baseURL + '/offers/best?numItems=' + payload.numItems + type + status,
      {
        headers: {
          'Authorization': 'Bearer ' +  process.env.ACCESS_TOKEN
        }
      })
      .then(res => {
        commit("setHomeOffer", res.data.data[0])
        commit("setLoading", false)
        return true
      }).catch(e => {
        console.log(e.message)
        commit("setLoading", false)
      })
  },
  async getOfferToCompare({ commit,state }) {
    if(state.session && state.session!==""){
      await axios.get(nuxtConfig.axios.baseURL + '/compare/'+state.session)
        .then(res => {
          const compare = res.data.compare.length>0 ? res.data.compare.map(v=>v.offer):[];
          commit("setCompare",compare);
          return true
        }).catch(e => {
          console.log(e.message)
        })
    }

  },
  async addOffers({ commit,state,dispatch },payload) {
    commit("setLoading", true)
    await axios.post(nuxtConfig.axios.baseURL + `/search_offers?page=${payload.page}`,payload.filters,
      {
        headers: {
          'Authorization': 'Bearer ' +  process.env.ACCESS_TOKEN
        }
      })
      .then(res => {
        commit('setOffersData', res.data.data)
        commit("setMeta", res.data.meta)
        setTimeout(function (){
          commit("setLoading", false)
        },1000)

        return true
      }).catch(e => {
        console.log(e.message)
        commit("setLoading", false)
      })
  },
  async addOfferToCompare({ commit,state,dispatch },offer) {
    await axios.post(nuxtConfig.axios.baseURL + '/session/create',{
      session:state.session,
      offer:offer.id
    },
      {
        headers: {
          'Authorization': 'Bearer ' +  process.env.ACCESS_TOKEN
        }
      })
      .then(res => {

        return true
      }).catch(e => {
        console.log(e.message)
      })
  },
  async delOfferToCompare({ commit,state },offer) {
    await axios.post(nuxtConfig.axios.baseURL + '/session/delete',{
      session:state.session,
      offer:offer.id
    },
      {
        headers: {
          'Authorization': 'Bearer ' +  process.env.ACCESS_TOKEN
        }
      })
      .then(res => {

        return true
      }).catch(e => {
        console.log(e.message)
      })
  },
  saveSession({ commit,state },session){
    commit("setSession",session);
  },
  async filterOffer({commit,state,dispatch},payload){
    commit("setLoading", true)
    await axios.post(nuxtConfig.axios.baseURL + `/search_offers?page=${payload.page}`,payload.filters,
      {
        headers: {
          'Authorization': 'Bearer ' +  process.env.ACCESS_TOKEN
        }
      })
      .then(res => {
        commit("setOffers", res.data)
        commit("setLoading", false)
        return true
      }).catch(e => {
        console.log(e.message)
        commit("setLoading", false)
      })
  },
  async search({commit},search){
    commit("setLoading", true)
    await axios.get(nuxtConfig.axios.baseURL + `/search/${search}`,
      {
        headers: {
          'Authorization': 'Bearer ' +  process.env.ACCESS_TOKEN
        }
      })
      .then(res => {
        commit("setResults", res.data)
        commit("setLoading", false)
        return true
      }).catch(e => {
        console.log(e.message)
        commit("setLoading", false)
      })
  },
  setLoading({commit},loading){
    commit('setLoading', loading)
  },
  clearSearch({commit}){
    commit("setResults", {pages: [], offers: []})
  }
}

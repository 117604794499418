
export const state = () => ({
  vn: [
    {
      title: 'Estrena coche',
      description: 'Vehículo nuevo <b>con o sin desembolso inicial</b>.',
      image: 'pn-1.jpg'
    },
    {
      title: 'A tu medida',
      description: 'Contratos que abarcan <b>desde los 36, 48 o 60 meses</b>. Tú eliges el plazo y kilometraje.',
      image: 'pn-2.jpg'
    },
    {
      title: 'Ahorro en carburante',
      description: '<b>Conduce los últimos modelos</b> más avanzados y eficientes del mercado.',
      image: 'pn-3.jpg'
    },
    {
      title: 'Control de gastos',
      description: '<b>Todos los servicios incluidos en una única cuota mensual fija:</b> mantenimiento, asistencia en carretera, servicio de reparación de daños y neumáticos incluido…',
      image: 'pn-4.jpg'
    },
    {
      title: 'Contratación',
      description: '<b>Proceso 100% online.</b>',
      image: 'pr-5.png'
    },
    {
      title: "Medioambiente",
      description: "Acceso a <b>vehículos más ecológicos</b>, a los que muchos clientes no podrían optar si se decantaran por la propiedad.",
      image: 'pn-6.jpg'
    }
  ],
  vo: [
    {
      title: 'Renting de seminuevos',
      description: 'Vehículos seminuevos en estado de uso, <b>completamente revisados</b>, con historial de mantenimiento e informe pericial procedentes de nuestros contratos de renting ya finalizados.',
      image: 'pr-1.png'
    },
    {
      title: 'Flexibilidad',
      description: '<b>Pruébalo durante 15 días o 1.000 km</b> y si no te convence, devuélvelo.',
      image: 'pr-2.png'
    },
    {
      title: 'Contrato',
      description: '<b>Contrato de hasta 24 meses</b> con opción de cancelación a los 6 meses.',
      image: 'pr-3.png'
    },
    {
      title: 'Tranquilidad',
      description: '<b>Todos los servicios incluidos en una única cuota mensual fija:</b> mantenimiento, asistencia en carretera, servicio de reparación de daños y neumáticos incluido…',
      image: 'pr-4.png'
    },
    {
      title: 'Contratación',
      description: '<b>Proceso 100% online.</b>',
      image: 'pr-5.png'
    },
    {
      title: "Si lo quieres… cómpralo",
      description: "Si resulta el coche de tus sueños, <b>puedes comprarlo.</b> Te descontaremos hasta 3 cuotas, a partir del sexto mes.",
      image: 'pr-6.png'
    }
  ]
})

export const getters = {

}

export const mutations = {

}

export const actions = {

}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.offer && _vm.offer.car
    ? _c(
        "div",
        {
          staticClass:
            "relative overflow-hidden transition-all duration-500 card carCard",
        },
        [
          _c(
            "div",
            {
              staticClass: "flex flex-row justify-between px-3 mx-3 card-top",
              class:
                _vm.offer.car.dgtLabel !== "Z" && _vm.offer.car.dgtLabel !== "E"
                  ? "justify-end"
                  : "justify-between",
            },
            [
              _c(
                "span",
                {
                  staticClass: "tag blue-tag",
                  staticStyle: {
                    padding: "4px 10px",
                    height: "30px",
                    cursor: "pointer",
                  },
                  on: { click: _vm.handleSeeOffers },
                },
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.offer.offers_count) +
                      " OFERTAS\n    "
                  ),
                ]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "img-offer'",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.handleSeeOffers.apply(null, arguments)
                },
              },
            },
            [
              _c("img", {
                staticClass: "cursor-pointer",
                class:
                  _vm.offer.car.vehicleStatus === "NUEVO"
                    ? "card-img"
                    : "card-img-n",
                attrs: { src: _vm.offer.offerDetail.urlPhoto, alt: "" },
              }),
            ]
          ),
          _vm._v(" "),
          _c("div", { staticClass: "h-full card-body" }, [
            _c(
              "h3",
              {
                staticClass: "brand-model cursor-pointer",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.handleSeeOffers.apply(null, arguments)
                  },
                },
              },
              [
                _vm._v(
                  "\n\n        " +
                    _vm._s(_vm.offer.car.brand.name) +
                    " " +
                    _vm._s(_vm.offer.car.model) +
                    "\n\n    "
                ),
              ]
            ),
            _vm._v(" "),
            _c("div", { staticClass: "my-2 card-divider" }),
            _vm._v(" "),
            _c("p", { staticClass: "from" }, [_vm._v("Desde")]),
            _vm._v(" "),
            _c("div", { staticClass: "flex flex-row justify-between w-full" }, [
              _c("span", { staticClass: "price" }, [
                _vm._v(
                  _vm._s(_vm.formatNumber(_vm.offer.offerDetail.monthFee)) +
                    "€/mes"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "flex items-center gap-1" }, [
                _c("img", {
                  staticClass: "w-5 h-5",
                  attrs: {
                    src: require("assets/images/icons/car.svg"),
                    alt: "Car icon",
                  },
                }),
                _vm._v(" "),
                _c(
                  "span",
                  {
                    staticClass: "compare",
                    on: { click: _vm.handleSeeOffers },
                  },
                  [_vm._v("Ver todos")]
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.offer?.type === "particular"
              ? _c("p", { staticClass: "from" }, [_vm._v("IVA incluido")])
              : _c("p", { staticClass: "from" }, [_vm._v("IVA no incluido")]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-2 card-divider" }),
          ]),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="container-regular">
    <div class="relative w-full px-8 pt-20 pb-14">

      <div class="relative mx-auto" style="max-width: 1350px;">
        <h2 class="title pb-3 mx-auto font-bold text-center lg:w-1/2">{{title}}</h2>
        <h3 class="subtitle pb-8 mx-auto text-center lg:w-1/2">{{subTitle}}</h3>
        <div
          class="grid grid-cols-1 gap-4 mt-4 md:grid-cols-2 lg:grid-cols-3"
        >
          <FlexCard :offer="offer" v-for="offer in offers" :key="offer.id"/>
          <div class="container mx-auto back-shadow"></div>
        </div>
        <p class="p-2 pt-3 text-sm text-[#89817e]">Oferta de renting sujeta a disponibilidad. Oferta calculada a 20.000 km/año con kilometraje limitado, sin entrada y sin franquicia. Periodo mínimo desde 1 mes. La fotografía puede no coincidir con la versión y/o el color ofertado. Unidades Limitadas.</p>
      </div>
    </div>
  </div>

</template>

<script>
import BestOffersCard from '../components/BestOffersCard'
import Spinner from '~/components/Spinner'
import Pagination from '~/components/Pagination.vue'
import turismoImagePath from '~/assets/images/flex/turismo-pequeno.png'
import comercialesImagePath from '~/assets/images/flex/furgon-carga-pasajeros.png'
import suvImagePath from '~/assets/images/flex/suv.png'
import furgoImagePath from '~/assets/images/flex/derivado-turismo.png'
import turismoMidImagePath from '~/assets/images/flex/turismo-medio-alto.png'
import todoImagePath from '~/assets/images/flex/todoterreno.png'

export default {
  name: 'FlexOffers',
  components: { Spinner, BestOffersCard, Pagination },
  data() {
    return {
      perPage: 3,
      hasMorePages: true,
      offers: [
        {
          title: 'OFERTA',
          price: 349,
          code: 'turismoSmall',
          image: turismoImagePath,
          category: 'Turismo pequeño',
          description: 'Muévete con todas las comodidades por la ciudad gracias a nuestra oferta de turismos pequeños.',
          models: 'Modelos tipo: Citroën C3/Peugeot 208/Opel Corsa/Renault Clio'
        },
        {
          title: 'OFERTA',
          price: 542,
          code: 'turismoMid',
          image: turismoMidImagePath,
          category: 'Turismo medio alto',
          description: 'Ideal para quien busca emociones fuertes y un alto nivel de desempeño en su coche y priman el confort.',
          models: 'Modelos tipo: Peugeot 508, Opel Insignia, Skoda Octavia, Renault Talisman, Citroën C4X'
        },
        {
          title: 'OFERTA',
          price: 383,
          code: 'furgo',
          image: furgoImagePath,
          category: 'Furgonetas',
          description: 'Vehículos comerciales de 3 puertas para que puedas transportar tu pequeña carga con todas las comodidades y seguridad.',
          models: 'Modelos tipo: Fiat Fiorino, Renault Kangoo, Citroën Berlingo, Peugeot Partner, Fiat Doblo'
        },
        {
          title: 'OFERTA',
          price: 575,
          code: 'comercial',
          image: comercialesImagePath,
          category: 'Furgón de carga y pasajeros',
          description: 'Vehículos comerciales con plazas y capacidad de transportar grandes y pesadas cargas.',
          models: 'Modelos tipo: Renault Trafic, Renault Expert, Citroën Jumpy, Opel Vivaro, Renault Master, Citroën Jumper, Renault Boxer'
        },
        {
          title: 'OFERTA',
          price: 562,
          code: 'suv',
          image: suvImagePath,
          category: 'SUV',
          description: 'Si lo que necesitas es salir de las carreteras convencionales.',
          models: 'Modelos tipo: Skoda Karoq 4x2, Seat Ateca 4x2, Opel Grandland'
        },
        {
          title: 'OFERTA',
          price: 670,
          code: 'todoterreno',
          image: todoImagePath,
          category: 'Todoterreno',
          description: 'Vehículos que no le tienen miedo a ningún elemento y te pueden llevar a cualquier parte.',
          models: 'Modelos tipo: Skoda Kodiaq, Toyota Land Cruiser, Toyota Rav4, Nissan X-trail'
        }
      ]
    }
  },
  props: {
    title: String,
    subTitle: String,
    type: {
      type: String,
      default: null,
      required: false,
    },
    status: {
      type: String,
      default: null,
      required: false,
    },
    numItems: {
      type: Number,
      default: 3,
      required: false,
    },
  }
}
</script>

<style scoped lang="scss">
h2.title {
  font-family: Chillax;
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  text-align: center;
  color: $--font-primary-color;
}
.see-all {
  // width: 278px;
  height: 62px;
  left: 581px;
  top: 2176px;
  background-color: $--secondary-color;
  border-radius: 100px;
  font-family: Chillax;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  padding: 0 60px;
}
.back-shadow {
  position: absolute;
  height: 1004px;
  max-width: 900px;
  width: 100%;
  max-height: 500px;
  background: #80b4ff;
  opacity: 0.4;
  filter: blur(160px);
  margin-top: 100px;
}
.img-curve-mobile {
  display: none;
  @media (max-width: 1024px) {
    display: block;
    margin-top: -43px;
    left: 0;
    z-index: 0;
  }
}
.more {
  @media (max-width: 1024px) {
    display: none;
  }
}
.more-mobile {
  @media (min-width: 1024px) {
    display: none;
  }
}
</style>

<template>
  <div
    class="relative overflow-hidden transition-all duration-500 card carCard"
    v-if="offer && offer.car"
  >
    <div
      class="flex flex-row justify-between px-3 mx-3 card-top"
      :class="offer.car.dgtLabel !== 'Z' && offer.car.dgtLabel !== 'E' ? 'justify-end' : 'justify-between'"
    >
      <span class="tag blue-tag" @click="handleSeeOffers" style="padding: 4px 10px; height: 30px;cursor: pointer;">
        {{ offer.offers_count }} OFERTAS
      </span>
    </div>
    <div class="img-offer'" @click.prevent="handleSeeOffers">
        <img class="cursor-pointer"
             :src="offer.offerDetail.urlPhoto"
             :class="offer.car.vehicleStatus === 'NUEVO' ? 'card-img' : 'card-img-n'"
             alt=""/>
    </div>

    <div class="h-full card-body">
      <h3 class="brand-model cursor-pointer" @click.prevent="handleSeeOffers">

          {{ offer.car.brand.name }} {{ offer.car.model }}

      </h3>
     <!-- <p class="fuel">
        <nuxt-img class="inline-block" src="~assets/images/icons/fuel-icon.png" />
        {{ offer.car.fuelType }}
      </p> -->
      <div class="my-2 card-divider"></div>
      <p class="from">Desde</p>
      <div class="flex flex-row justify-between w-full">
        <span class="price">{{ formatNumber(offer.offerDetail.monthFee) }}€/mes</span>
        <div class="flex items-center gap-1">
          <img src="~assets/images/icons/car.svg" class="w-5 h-5" alt="Car icon" />
          <span class="compare" @click="handleSeeOffers">Ver todos</span>
        </div>
      </div>

      <p class="from" v-if="offer?.type === 'particular'">IVA incluido</p>
      <p class="from" v-else>IVA no incluido</p>
      <div class="mt-2 card-divider"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BestOffersCard',
  props: {
    offer: Object,
  },
  data() {
    return {}
  },
  methods: {
    async handleSeeOffers() {
      await this.$store.dispatch('filters/setQuality',  ['USADO'])
      const { brand, model } = this.offer.car
      await this.$router.push({
        path: `/ofertas`,
        query: { brand: brand, offerType: 'particular', quality: 'USADO', model: { id: model, name: model } },
        params: { brand: brand, model: { id: model, name: model } },
      })
    },
    formatNumber(x) {
      if (!x) {
        return ''
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    },
    removeFromCompare() {
      this.$store.dispatch('offers/removeFromCompare', this.offer)
    },
    addToCompare() {
      this.$store.dispatch('offers/addToCompare', this.offer)
    },
  },
  computed: {
    isInCompare() {
      return this.$store.getters['offers/isInCompare'](this.offer.id)
    },
  },
}
</script>

<style scoped lang="scss">
.from {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.card {
  padding-bottom: 0;
}

.card-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding: 1.25rem 1.25rem 1.75rem 1.25rem;
  padding-bottom: 35px;

  .brand-model {
    font-family: Chillax;
    font-weight: 500;
    color: $--assistive-blue;
    margin-bottom: auto;
  }
}

.emission-img {
  max-width: 40px;
  width: 100%;
  margin-bottom: 0%;
}

.compare {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: $--assistive-blue;
  cursor: pointer;
}

.img-offer {
  height: 200px;
  margin-top: 25px;
  min-width: 250px;

  a {
    height: 200px;
    display: block;
    position: relative;
  }
}

.card-img {
  object-fit: contain;
  position: relative;
  height: 200px;
  margin: 0 auto;
}

.card-img-n {
  object-fit: contain;
  position: relative;
  // height: 200px;
  width: 100%;
}

.card-img-clipped {
  min-width: 250px;

  a {
    display: block;
    position: relative;
  }
}

.carCard {
  height: 500px;
  max-width: 300px;
}

.card-top {
  min-height: 60px;
  align-items: center;

  img {
    order: 1;
  }

  .vn-button {
    order: 0;
  }
}

.carburante {
  //background: linear-gradient(90deg, #00b67a 0%, #44e1c3 100%);
  background: #0F3549;
  font-family: Source Sans Pro;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.04em;
  text-align: center;
  padding-top: 5px;
  padding-bottom: 5px;

  b {
    font-family: Montserrat;
    font-size: 23px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }
}
.price {
  font-family: Chillax;
  font-weight: 500;
}
.brand-model {
  font-size: 26px!important;
}
</style>

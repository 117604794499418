<template>
  <div>
    <div class="flex items-center justify-between pt-4 border-t">
      <div class="label">COMPARTE ESTA OFERTA</div>
      <div class="flex items-center gap-2">
        <button>
          <a :href="`mailto:?subject=Oferta Ayvens&body=Hola te comparto esta oferta genial: ${route}utm_source=email&utm_medium=referal&utm_campaign=comparte_esta_oferta`">
            <img :src="require('~/assets/images/icons/email-green.svg')" alt />
          </a>
        </button>
        <a :href="`https://web.whatsapp.com/send?text==${route}utm_source=whatsapp&utm_medium=referal&utm_campaign=comparte_esta_oferta`">
          <img :src="require('~/assets/images/icons/whatsapp-green.svg')" alt />
        </a>
        <a :href="`https://telegram.me/share/url?url=${route}utm_source=telegram&utm_medium=referal&utm_campaign=comparte_esta_oferta`">
          <img :src="require('~/assets/images/icons/telegram-green.svg')" alt />
        </a>
      </div>
    </div>
    <p class="p-2 pt-3 text-xs text-[#989A9A]">
      {{  legalText }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'ShareOffer',
  props: {
    type: String
  },
  computed: {
    route() {
      return window.location.href;
    },
    legalText() {
     switch (this.type) {
       case 'USADO':
         return 'Oferta renting seminuevos válida sólo en Península y Baleares hasta fin de existencias. Unidades limitadas.';
       case 'NUEVO':
         return 'Oferta renting nuevo válida sólo en Península y Baleares hasta fin de existencias y sin entrada. La fotografía puede no coincidir con la versión y/o el color ofertado.';
       case 'flex':
         return 'IVA no incluido';
       default:
         return '';
     }
    }
  }
}
</script>

<style scoped>

.label {
  font-family: Chillax;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  text-align: center;
  text-transform: uppercase;
  color: #333333;
}
</style>

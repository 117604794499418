<template>
  <div class="container-regular">
    <div class="relative w-full px-8 pt-20 pb-14 reset-padding">
      <div class="relative mx-auto" style="max-width: 1350px">
        <h2 class="pb-3 h2-title">{{ title }}</h2>
        <p class="mx-auto text-center h3-subtitle" v-if="subTitle">{{ subTitle }}</p>
        <div v-if="!$store.state.offers.loading"
          class="grid grid-cols-1 gap-4 mt-5 md:mt-12 md:grid-cols-2 lg:grid-cols-3">
          <BestOffersCard :offer="offer" v-for="offer in $store.state.offers.bestOffers.data" :key="offer.id" />
          <div class="container mx-auto back-shadow"></div>
        </div>

        <div v-if="!$store.state.offers.loading" class="relative flex justify-center w-full mt-20 cta-box">
          <NuxtLink v-if="type && status" :to="`/ofertas?offerType=${type}&quality=${status}`" class="cursor-pointer">
            <button class="see-all more" id="cta-see-all-offers">Ver todas las ofertas</button>
            <button class="see-all more-mobile" id="cta-see-all-offers-mobile">Más ofertas</button>
          </NuxtLink>
          <NuxtLink v-else to="/ofertas" class="cursor-pointer">
            <button class="see-all more" id="cta-see-all-offers">Ver todas las ofertas</button>
            <button class="see-all more-mobile" id="cta-see-all-offers-mobile">Más ofertas</button>
          </NuxtLink>
        </div>
        <Spinner v-else />
      </div>
    </div>
  </div>
</template>

<script>
import BestOffersCard from '../components/BestOffersCard'
import compare from '~/components/Compare'
import Spinner from '~/components/Spinner'
import Pagination from '~/components/Pagination.vue'
import axios from "axios";
import LowerOffersCard from './LowerOffersCard.vue';
export default {
  name: 'BestOffers',
  components: { Spinner, BestOffersCard, LowerOffersCard, Pagination },
  data() {
    return {
      perPage: 3,
      hasMorePages: true,
    }
  },
  props: {
    title: String,
    subTitle: String,
    type: {
      type: String,
      default: null,
      required: false,
    },
    status: {
      type: String,
      default: null,
      required: false,
    },
    numItems: {
      type: Number,
      default: 3,
      required: false,
    },
  },
  created() {
    this.getOffers(this.numItems, this.type)
  },
  methods: {
    async getOffers(numItems, type) {
      let vm = this
        await vm.$store.dispatch('offers/getBestOffers', {
          numItems: numItems,
          type: type,
          status: this.status,
        })
    },
    showMore(page) {
      //console.log('Page',page);
      this.page = page
      this.currentPage = page
    },
  },
}
</script>

<style scoped lang="scss">
.h2-title {
  font-family: Chillax;
  font-size: 42px;
  line-height: 48px;
  text-align: center;
  margin: 0 auto;
  max-width: 700px;

  @media (max-width: 767px) {
    font-size: 28px;
    line-height: 36px;
  }
}

.see-all {
  // width: 278px;
  height: 62px;
  left: 581px;
  top: 2176px;
  background-color: $--primary-color-cp;
  border-radius: 100px;
  font-family: Chillax;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: #ffffff;
  padding: 0 60px;
}

.back-shadow {
  position: absolute;
  height: 74%;
  max-width: 900px;
  width: 100%;
  // background: #80b4ff;
  opacity: 0.4;
  filter: blur(160px);
  // position: absolute;
  // height: 1004px;
  // max-width: 900px;
  // width: 100%;
  // max-height: 500px;
  // background: #80b4ff;
  // opacity: 0.4;
  // filter: blur(160px);
  // margin-top: 100px;
}

.img-curve-mobile {
  display: none;

  @media (max-width: 1024px) {
    display: block;
    margin-top: -43px;
    left: 0;
    z-index: 0;
  }
}

.more {
  @media (max-width: 1024px) {
    display: none;
  }
}

.more-mobile {
  @media (min-width: 1024px) {
    display: none;
  }
}

.cta-box {
  @media (max-width: 1024px) {
    margin-top: 3rem;
  }
}
</style>

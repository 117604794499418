<template>
  <div class="transition-all duration-500 card carCard" style="min-width: 300px;
    min-height: 350px;">
    <!-- title -->
    <div class="flex flex-row justify-center px-3 pt-3 mx-3 mt-3">
      <h3 class="offer-title">{{ offer.title }} <br>
        <!-- category -->
        <div class="flex flex-row justify-center mb-1">
          <span class="offer-subtitle">{{ offer.category }}</span>
        </div>
      </h3>
    </div>
    <!-- img -->
    <img :src="offer.image"  :alt="offer.title"/>
    <!-- description and model -->
    <div class="px-5">
      <div class="flex-price w-full">
        <p class="price-text">Desde:</p>
        <p class="price-value">{{ offer.price }}&#8364 <span>/mes IVA no incluido </span></p>
      </div>
      <div class="flex flex-row w-full">
        <p class="offer-paragraph">{{ offer.description }}</p>
      </div>
      <div class="my-2 card-divider"></div>
      <div class="flex flex-row w-full">
        <p class="offer-footnote">{{ offer.models }}</p>
      </div>
    </div>
    <a  @click="seeMoore(offer.category)" class="link-button">Me interesa</a>

  </div>
</template>

<script>
export default {
  name: 'FlexCard',
  props: {
    offer: Object,
  },
  data() {
    return {}
  },
  methods: {
    seeMoore(code) {
      this.$store.dispatch('offers/setFlexCode', code)
      document.getElementById('flex-form').scrollIntoView({ behavior: 'smooth' });
    },
    formatNumber(x) {
      if (!x) {
        return '';
      }
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
    },
    removeFromCompare() {
      this.$store.dispatch('offers/removeFromCompare', this.offer)
    },
    addToCompare() {
      this.$store.dispatch('offers/addToCompare', this.offer)
    },

  },
  computed: {
    isInCompare() {
      return this.$store.getters['offers/isInCompare'](this.offer.id)
    },
  },
}
</script>

<style scoped lang="scss">
.flex-price {
  margin: 16px 16px 0;

  p {
    display: block;
  }
  .price-text {
    font-size: 14px;
    font-weight: 400;
    color: #2C2C2C;
  }
  .price-value {
    font-size: 30px;
    line-height: 30px;
    font-weight: 600;
    color: #2C2C2C;
    span {
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.offer-title {
  color: $--secondary-color;
  text-transform: uppercase;
  font-family: 'Lato', sans-serif;
  font-weight: 800;
  margin: 0;
  font-size: 28px;
  line-height: 2rem;
  text-align: center;
  .offer-subtitle {
    font-size: 22px;
    text-transform: none;
  }
}

.offer-paragraph {
  line-height: 1.5rem;
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  color: $--font-primary-color;
  margin: 16px;
  font-size: 1rem;
  min-height: 80px;
}
.link-button {
  cursor: pointer;
  background-color: #1973B8;
  color: white;
  font-family: 'Lato', sans-serif;
  font-weight: 500;
  font-size: 18px;
  height: 60px;
  display: flex;
  justify-content: center;
  justify-items: center;
  margin-top: auto;
  align-items: center;
  margin-left: 15px;
  margin-right: 15px;
}
.offer-footnote {
  line-height: 1.5rem;
  font-family: 'Source Sans 3';
  font-weight: 400;
  color: #1973B8;
  margin: 16px auto;
  font-size: .875rem;
  line-height: 1.5rem;
}

.from {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  display: flex;
  align-items: flex-end;
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.offer-subtitle {
  font-family: Chillax;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  color: $--assistive-blue;
  margin-bottom: 15px;
}

.emission-img {
  max-width: 40px;
  width: 100%;
}

.compare {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.02em;
  text-transform: uppercase;
  color: #000000;
  margin-top: 5px;
  cursor: default;

  // &:hover {
  //   color: #4391ff;
  // }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "flex-form" } }, [
    _c(
      "form",
      {
        staticClass: "flex flex-col px-7 lg:p-0",
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.sendEloqua.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "flex flex-col gap-3 pt-9" }, [
          _c("div", { staticClass: "group" }, [
            _c(
              "div",
              { staticClass: "label", class: _vm.from ? "text-white" : "" },
              [_vm._v("Empresa*")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.eloqua.company,
                  expression: "eloqua.company",
                },
              ],
              attrs: { type: "text", name: "company" },
              domProps: { value: _vm.eloqua.company },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.eloqua, "company", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _vm.isCompany
            ? _c("div", { staticClass: "group" }, [
                _c(
                  "div",
                  { staticClass: "label", class: _vm.from ? "text-white" : "" },
                  [_vm._v("Flota total*")]
                ),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model",
                      value: _vm.eloqua.leadTotalFleetPotential1,
                      expression: "eloqua.leadTotalFleetPotential1",
                    },
                  ],
                  attrs: {
                    type: "text",
                    name: "flota",
                    placeholder: "Nro de vehículos de la empresa",
                    required: _vm.isCompany,
                  },
                  domProps: { value: _vm.eloqua.leadTotalFleetPotential1 },
                  on: {
                    input: function ($event) {
                      if ($event.target.composing) return
                      _vm.$set(
                        _vm.eloqua,
                        "leadTotalFleetPotential1",
                        $event.target.value
                      )
                    },
                  },
                }),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "group" }, [
            _c(
              "div",
              { staticClass: "label", class: _vm.from ? "text-white" : "" },
              [_vm._v("Nombre*")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.eloqua.firstName,
                  expression: "eloqua.firstName",
                },
              ],
              attrs: {
                type: "text",
                id: "fe7421",
                name: "firstName",
                required: "",
              },
              domProps: { value: _vm.eloqua.firstName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.eloqua, "firstName", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "group" }, [
            _c(
              "div",
              { staticClass: "label", class: _vm.from ? "text-white" : "" },
              [_vm._v("Apellidos*")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.eloqua.lastName,
                  expression: "eloqua.lastName",
                },
              ],
              attrs: {
                type: "text",
                id: "fe7422",
                name: "lastName",
                required: "",
              },
              domProps: { value: _vm.eloqua.lastName },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.eloqua, "lastName", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "group" }, [
            _c(
              "div",
              { staticClass: "label", class: _vm.from ? "text-white" : "" },
              [_vm._v("Email*")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.eloqua.emailAddress,
                  expression: "eloqua.emailAddress",
                },
              ],
              attrs: {
                type: "email",
                name: "emailAddress",
                id: "fe7425",
                pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$",
                required: "",
              },
              domProps: { value: _vm.eloqua.emailAddress },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.eloqua, "emailAddress", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "group" }, [
            _c(
              "div",
              { staticClass: "label", class: _vm.from ? "text-white" : "" },
              [_vm._v("Teléfono*")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.eloqua.mobilePhone,
                  expression: "eloqua.mobilePhone",
                },
              ],
              attrs: {
                pattern: "[0-9]{9}",
                type: "tel",
                minlength: "9",
                maxlength: "9",
                id: "fe7423",
                name: "mobilePhone",
                required: "",
              },
              domProps: { value: _vm.eloqua.mobilePhone },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.eloqua, "mobilePhone", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "group" }, [
            _c(
              "div",
              { staticClass: "label", class: _vm.from ? "text-white" : "" },
              [_vm._v("Código postal*")]
            ),
            _vm._v(" "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.eloqua.zipPostal,
                  expression: "eloqua.zipPostal",
                },
              ],
              attrs: {
                pattern: "[0-9]{5}",
                type: "tel",
                minlength: "5",
                maxlength: "5",
                name: "zipPostal",
                id: "fe7424",
                required: "",
              },
              domProps: { value: _vm.eloqua.zipPostal },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.eloqua, "zipPostal", $event.target.value)
                },
              },
            }),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "group" }, [
            _c(
              "div",
              { staticClass: "label", class: _vm.from ? "text-white" : "" },
              [_vm._v("Déjanos un mensaje*")]
            ),
            _vm._v(" "),
            _c("textarea", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.eloqua.paragraphText,
                  expression: "eloqua.paragraphText",
                },
              ],
              attrs: {
                rows: "8",
                required: "",
                name: "descriptionLarge1",
                id: "fe7426",
              },
              domProps: { value: _vm.eloqua.paragraphText },
              on: {
                input: function ($event) {
                  if ($event.target.composing) return
                  _vm.$set(_vm.eloqua, "paragraphText", $event.target.value)
                },
              },
            }),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "my-5" },
          [
            _c("input", { attrs: { type: "checkbox", required: "" } }),
            _vm._v(" "),
            _c("span", { class: _vm.from ? "text-white" : "" }, [
              _vm._v("He leído y acepto la "),
            ]),
            _vm._v(" "),
            _c(
              "NuxtLink",
              {
                staticClass: "link",
                attrs: { to: "/politica-de-proteccion-de-datos" },
              },
              [_vm._v("Política de privacidad")]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c("div", { staticClass: "flex pt-5" }, [
          _c(
            "button",
            { staticClass: "submit", attrs: { disabled: _vm.disableButton } },
            [_vm._v("Enviar")]
          ),
        ]),
        _vm._v(" "),
        _vm.error
          ? _c("div", { staticClass: "error" }, [
              _c("p", [_vm._v(_vm._s(_vm.error))]),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
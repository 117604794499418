var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "flex items-center justify-between pt-4 border-t" },
      [
        _c("div", { staticClass: "label" }, [_vm._v("COMPARTE ESTA OFERTA")]),
        _vm._v(" "),
        _c("div", { staticClass: "flex items-center gap-2" }, [
          _c("button", [
            _c(
              "a",
              {
                attrs: {
                  href: `mailto:?subject=Oferta Ayvens&body=Hola te comparto esta oferta genial: ${_vm.route}utm_source=email&utm_medium=referal&utm_campaign=comparte_esta_oferta`,
                },
              },
              [
                _c("img", {
                  attrs: {
                    src: require("~/assets/images/icons/email-green.svg"),
                    alt: "",
                  },
                }),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: `https://web.whatsapp.com/send?text==${_vm.route}utm_source=whatsapp&utm_medium=referal&utm_campaign=comparte_esta_oferta`,
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("~/assets/images/icons/whatsapp-green.svg"),
                  alt: "",
                },
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              attrs: {
                href: `https://telegram.me/share/url?url=${_vm.route}utm_source=telegram&utm_medium=referal&utm_campaign=comparte_esta_oferta`,
              },
            },
            [
              _c("img", {
                attrs: {
                  src: require("~/assets/images/icons/telegram-green.svg"),
                  alt: "",
                },
              }),
            ]
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c("p", { staticClass: "p-2 pt-3 text-xs text-[#989A9A]" }, [
      _vm._v("\n    " + _vm._s(_vm.legalText) + "\n  "),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
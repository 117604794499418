
export const state = () => ({
  vo: [
    {
      title: '¿Qué es Ayvens Flex?',
      description: 'Ayvens Flex ofrece a los clientes un servicio de renting flexible que rentabiliza el negocio mientras responde a las necesidades puntuales de cada proyecto empresarial. Tú eliges el tipo de vehículo que necesitas, los kilómetros que vas a recorrer y podrás devolverlo, cuando ya no lo necesites, sin penalización por la devolución.'
    },
    {
      title: '¿Puedo seleccionar la marca y modelo?',
      description: 'El comercial te asignará un vehículo según la disponibilidad del segmento elegido en el momento en el que lo has solicitado.'
    },
    {
      title: '¿Qué servicios incluye Ayvens Flex?',
      description: 'Todo incluido en tu cuota mensual sin imprevistos ni sorpresas:\n' +
        '<ul><li>-Desde 20.000 km anuales.</li>\n' +
        '<li>-Reparación de daños propios.</li>\n' +
        '<li>-Asistencia en carretera 24h/365 días.</li>\n' +
        '<li>-Mantenimiento y reparaciones.</li>\n' +
        '<li>-Vehículo ilimitado de sustitución por avería y/o accidente.</li>'
    },
    {
      title: '¿Quién puede conducir el renting contratado de Ayvens Flex?',
      description: 'La empresa que contrata un vehículo de Ayvens Flex puede dejarle ese vehículo a cualquiera de los empleados que tengan carnet de conducir (en vigor).'
    },
    {
      title: '¿Puedo viajar al extranjero con mi vehículo?',
      description: 'Si quieres viajar al extranjero con tu vehículo tienes que llamar a Atención al Cliente (910 554 943) para que te faciliten la documentación requerida por el país al que quieras viajar.'
    },
    {
      title: '¿Hay penalización por cancelar el contrato de Ayvens Flex?',
      description: 'El contrato no tiene permanencia a partir del primer mes.'
    },
    {
      title: '¿Se puede contratar un renting Flexible en Canarias, Baleares, Ceuta o Melilla?',
      description: 'Por supuesto, entregamos vehículos fuera de la península. Consúltanos las condiciones, importes y disponibilidad.'
    }
  ],
  voFull: [
    {
      title: '¿Qué es Ayvens Flex?',
      description: 'Ayvens Flex ofrece a los clientes un servicio de renting flexible que rentabiliza el negocio mientras responde a las necesidades puntuales de cada proyecto empresarial. Tú eliges el tipo de vehículo que necesitas, los kilómetros que vas a recorrer y podrás devolverlo, cuando ya no lo necesites, sin penalización por la devolución.'
    },
    {
      title: '¿Puedo seleccionar la marca y modelo?',
      description: 'El comercial te asignará un vehículo según la disponibilidad del segmento elegido en el momento en el que lo has solicitado.'
    },
    {
      title: '¿Qué servicios incluye Ayvens Flex?',
      description: 'Todo incluido en tu cuota mensual sin imprevistos ni sorpresas:\n' +
        '<ul><li>-Desde 20.000 km anuales.</li>\n' +
        '<li>-Reparación de daños propios.</li>\n' +
        '<li>-Asistencia en carretera 24h/365 días.</li>\n' +
        '<li>-Mantenimiento y reparaciones.</li>\n' +
        '<li>-Vehículo ilimitado de sustitución por avería y/o accidente.</li>'
    },
    {
      title: '¿Qué documentación es necesaria para contratar mi renting de Ayvens Flex?',
      description: 'Es necesario presentar la siguiente documentación: CIF de empresa, escritura de poderes, DNI del apoderado, carta de titularidad de los socios de la empresa, SEPA, justificante de cuenta bancaria, auditoria del año anterior, IVA, cuentas actualizadas.',
    },
    {
      title: '¿Puedo hacer la contratación online?',
      description: 'La contratación se realizará con un comercial que te ayudará a lo largo de todo el proceso de contratación. Te indicará toda la documentación necesaria para la aceptación del contrato de renting (valoración del riesgo); te explicará las condiciones generales y particulares y realizará la gestión de la entrega del vehículo. ',
    },
    {
      title: '¿Dónde me entregarán mi coche?',
      description: 'El coche te lo entregarán en la campa indicada. En caso de que lo quieras recoger en otro emplazamiento tendrás que abonar los gastos de desplazamiento del vehículo hasta el lugar de la entrega solicitado.',
    },
    {
      title: '¿Qué pasa si excedo los kilómetros contratados?',
      description: 'Se realizará el ajuste correspondiente a los kilómetros contratados.'
    },
    {
      title: '¿Quién puede conducir el renting contratado de Ayvens Flex?',
      description: 'La empresa que contrata un vehículo de Ayvens Flex puede dejarle ese vehículo a cualquiera de los empleados que tengan carnet de conducir (en vigor).'
    },
    {
      title: '¿Puedo viajar al extranjero con mi vehículo?',
      description: 'Si quieres viajar al extranjero con tu vehículo tienes que llamar a Atención al Cliente (910 554 943) para que te faciliten la documentación requerida por el país al que quieras viajar.'
    },
    {
      title: '¿Cómo encuentro un taller que se ocupe del servicio y mantenimiento o reparaciones de daños?',
      description: 'Tienes que llamar a Atención al Cliente (910 554 943) y ellos te indicarán todos los talleres concertados con Ayvens que más cerca se encuentran de tu ubicación.'
    },
    {
      title: '¿Dispongo de servicio de recogida y entrega cuando tenga que llevar el coche o la flota de coches al taller?',
      description: 'No, el traslado al taller no está incluido.'
    },
    {
      title: '¿Cómo paso la ITV de mi coche de renting Ayvens Flex?',
      description: 'Tienes que llamar a atención al cliente para que te faciliten el bono correspondiente a la ITV de el vehículo. En el caso de que el vehículo no pase la ITV hay que avisar a atención al cliente y Ayvens se hará cargo de las reparaciones necesarias para que el vehículo pueda pasar la ITV.'
    },
    {
      title: '¿Puedo devolver el vehículo con la ITV caducada?',
      description: 'Tienes que llamar a Atención al Cliente (910 554 943) para que te faciliten el bono correspondiente a la ITV de el vehículo. En el caso de que el vehículo no pase la ITV hay que avisar a atención al cliente y Ayvens se hará cargo de las reparaciones necesarias para que el vehículo pueda pasar la ITV'
    },
    {
      title: '¿Disfruto de alguna ventaja fiscal al contratar un coche de renting para mi empresa?',
      description: 'Disfrutarás de una deducción en el pago del Impuesto de sociedades o en el IRPF, dependiendo del régimen de tributación.'
    },
    {
      title: '¿Tengo alguna deducción en los pagos del IVA?',
      description: 'En función del grado de afectación a la actividad empresarial según las normas legales pudiendo ser hasta del 100%.'
    },
    {
      title: '¿Hay penalización por cancelar el contrato de Ayvens Flex?',
      description: 'El contrato no tiene permanencia a partir del primer mes.'
    },
    {
      title: '¿Qué ocurre cuando finaliza mi contrato de renting de Ayvens Flex?',
      description: 'Tienes que devolver el vehículo, a partir del tercer mes la recogida es gratuita o bien puedes entregarlo en la delegación indicada.'
    },
    {
      title: '¿Cómo tengo que devolver mi vehículo de Ayvens Flex al finalizar el contrato?',
      description: 'Tienes que devolverlo limpio (tanto el exterior como el interior), con todos los elementos que se entregaron y vaciar tus objetos personales, para que los profesionales de Ayvens puedan evaluar el estado del vehículo.'
    },
    {
      title: '¿Puede ir cualquier persona a devolver el vehículo?',
      description: 'La devolución tiene que efectuarla el titular del contrato o el conductor habitual informado por parte de la empresa ya que se realiza un acta de devolución y una verificación del estado del vehículo.'
    },
    {
      title: '¿Se puede contratar un renting Flexible en Canarias, Baleares, Ceuta o Melilla?',
      description: 'Por supuesto, entregamos vehículos fuera de la península. Consúltanos las condiciones, importes y disponibilidad.'
    }
  ],
  vn: [
    {
      title: '¿Qué requisitos son necesarios para conducir un renting de empresa?',
      description: 'El conductor debe disponer de carnet de conducir en vigor y una edad comprendida entre los 18 y los 70 años.',
    },
    {
      title: '¿Puedo hacer la contratación online?',
      description: 'No. La contratación se realizará con un comercial que te ayudará a lo largo de todo el proceso de contratación. Te indicará toda la documentación necesaria para la aceptación del contrato de renting (valoración del riesgo); te explicará las condiciones generales y particulares y realizará la gestión de la entrega del vehículo.',
    },
    {
      title: '¿Dónde me entregarán mi coche?',
      description: 'Te entregarán el coche en el concesionario acordado.',
    },
    {
      title: '¿Cómo encuentro un taller que se ocupe del servicio y mantenimiento o reparaciones de daños?',
      description: 'Tienes que llamar a atención al cliente y ellos te indicarán todos los talleres concertados con Ayvens que más cerca se encuentran de tu ubicación.',
    },
    {
      title: '¿Puedo viajar al extranjero con mi vehículo?',
      description: 'Si quieres viajar al extranjero con tu vehículo tienes que llamar a Atención al Cliente (913 336 717) para que te faciliten la documentación requerida por el país al que quieras viajar.',
    },
    {
      title: '¿Qué ocurre cuando finaliza mi contrato de renting?',
      description: 'Al finalizar tu contrato de renting puedes solicitar una prórroga de este o una renovación por otro vehículo. Sino optas por alguna de las dos opciones anteriores tienes que devolver tu vehículo.',
    },
    {
      title: '¿Puedo contratar un renting de Ayvens en Canarias, Baleares, Ceuta o Melilla?',
      description: 'Por supuesto, entregamos vehículos fuera de la península. Consúltanos las condiciones, importes y disponibilidad.'
    }
  ],
  vnFull: [
    {
      title: '¿Qué requisitos son necesarios para conducir un renting de empresa?',
      description: 'El conductor debe disponer de carnet de conducir en vigor y una edad comprendida entre los 18 y los 70 años.',
    },
    {
      title: '¿Puedo hacer la contratación online?',
      description: 'No. La contratación se realizará con un comercial que te ayudará a lo largo de todo el proceso de contratación. Te indicará toda la documentación necesaria para la aceptación del contrato de renting (valoración del riesgo); te explicará las condiciones generales y particulares y realizará la gestión de la entrega del vehículo.',
    },
    {
      title: '¿Dónde me entregarán mi coche?',
      description: 'Te entregarán el coche en el concesionario acordado.',
    },
    {
      title: '¿Cómo encuentro un taller que se ocupe del servicio y mantenimiento o reparaciones de daños?',
      description: 'Tienes que llamar a atención al cliente y ellos te indicarán todos los talleres concertados con Ayvens que más cerca se encuentran de tu ubicación.',
    },
    {
      title: '¿Dispongo de servicio de recogida y entrega cuando tenga que llevar el coche o la flota de coches al taller?',
      description: 'Si, este servicio está incluido, cuando tengas que llevar tu coche al taller tendrás que contactar con Atención al Cliente, en el número: 913 336 717 y ellos te gestionarán la recogida y entrega del vehículo. Este servicio no está disponible para el cambio de neumáticos.',

    },
    {
      title: '¿Puedo viajar al extranjero con mi vehículo?',
      description: 'Si quieres viajar al extranjero con tu vehículo tienes que llamar a Atención al Cliente (913 336 717) para que te faciliten la documentación requerida por el país al que quieras viajar.',
    },
    {
      title: '¿Disfruto de alguna ventaja fiscal al contratar un coche de renting para mi empresa?',
      description: 'Disfrutarás de una deducción en el pago del Impuesto de sociedades o en el IRPF, dependiendo del régimen de tributación.',
    },
    {
      title: '¿Qué documentos son necesarios para contratar un renting de empresa?',
      description: 'El CIF de empresa, escritura de poderes, DNI del apoderado, carta de titularidad de los socios de la empresa, SEPA, justificante de cuenta bancaria, auditoria del año anterior, IVA, cuentas actualizadas.',
    },
    {
      title: '¿Tengo alguna deducción en los pagos del IVA?',
      description: 'En función del grado de afectación a la actividad empresarial según las normas legales pudiendo ser hasta del 100%.',
    },
    {
      title: '¿Qué ocurre cuando finaliza mi contrato de renting?',
      description: 'Al finalizar tu contrato de renting puedes solicitar una prórroga de este o una renovación por otro vehículo. Sino optas por alguna de las dos opciones anteriores tienes que devolver tu vehículo.',
    },
    {
      title: '¿Cómo tengo que devolver mi vehículo de renting de empresa al finalizar el contrato?',
      description: 'Tienes que devolverlo limpio (tanto exterior como interior), con todos los elementos que se entregaron y vaciar tus objetos personales, para que los profesionales de Ayvens puedan evaluar el estado del vehículo.',
    },
    {
      title: '¿Puede ir cualquier persona a devolver el vehículo?',
      description: 'La devolución tiene que realizarla el titular del contrato o el conductor habitual informado por parte de la empresa ya que se realiza un acta de devolución y una verificación del estado del vehículo.'
    },
    {
      title: '¿Qué es  Ayvens Carsharing o coche compartido? ',
      description: 'Es una solución que permite a las empresas disponer de una flota de vehículos dedicada a un uso compartido entre sus empleados, para todos los días; en sus trayectos profesionales o privados. Será el cliente el que defina las reglas y los criterios de uso en base a sus necesidades.'
    },
    {
      title: '¿Qué funciones de Ayvens Carsharing gestiona y controla la empresa?',
      description: '<ul>' +
        '<li>-Gestión de su perfil profesional y/o privado a través de la App.</li>\n' +
        '<li>-Reserva de vehículos para uso tanto profesional como privado.</li>\n' +
        '<li>-Autorización o denegación a un conductor para un uso concreto y clasificación de conductores según segmentos.</li>\n' +
        '<li>-Fácil acceso a kilometrajes y consumos de carburante.</li>\n' +
        '<li>-Comprobación del ahorro de costes diarios y aumento de la eficiencia de la flota.</li>' +
        '</ul>',
    },
    {
      title: '¿Qué funciones de Ayvens Carsharing gestiona y controla el empleado?',
      description: '<ul>' +
        '<li>-Gestión de su perfil profesional y/o privado a través de la App.</li>\n' +
        '<li>-Reserva de vehículos para uso tanto profesional como privado.</li>\n' +
        '<li>-Apertura y cierre del vehículo mediante un PIN.</li>\n' +
        '<li>-Gestión de viajes para controlar las reservas.</li>\n' +
        '<li>-Notificación de retrasos en caso de que el conductor llegue tarde a la reserva; la aplicación envía una notificación.</li>\n' +
        '<li>-Feedback del estado del vehículo al comienzo de cada viaje; se solicita al conductor que escriba un comentario sobre el estado de este.</li></ul>',
    },
    {
      title: '¿Qué es el servicio BlueFleet de Ayvens Automotive?',
      description: 'Gracias al proyecto Ayvens BlueFleet tienes asesoramiento acerca de las nuevas tecnologías, recibirás un reporting de emisiones, te ofrecemos vehículos más ecológicos, cursos de conducción y acceso a conferencias en foros y observatorios.',
    },
    {
      title: '¿Qué es el servicio ProFleet de Ayvens Automotive?',
      description: 'Con este servicio podrás controlar el kilometraje profesional y privado, el consumo de combustible y la ubicación del vehículo 24/7, calcular las emisiones de CO2, la utilización de la flota, las horas de conducción, la conducta al volante y, además, operarás con mayor eficacia, reducirás costes operacionales y mejorarás la seguridad de tu flota.',
    },
    {
      title: '¿Qué ventajas tiene la tarjeta Ayvens Solred?',
      description: 'Optimizas los procedimientos administrativos, agilizas los repostajes y peajes, te da la seguridad de que sólo está vinculada a un vehículo, recibirás alertas por malos usos de la tarjeta y obtendrás descuentos en el precio de los carburantes.',
    },
    {
      title: '¿Qué debo hacer en caso de robo o extravío de mi tarjeta Ayvens Solred?',
      description: 'Tienes que ponerte en contacto con Ayvens (913 336 717) y nosotros te indicaremos como proceder.',
    },
    {
      title: '¿En qué casos de inmovilización podré disponer de un vehículo de sustitución?',
      description: 'Siempre que tengas el servicio de vehículo contratado.',
    },
    {
      title: '¿Es elevado el coste de un vehículo de sustitución?',
      description: 'Depende de cada oferta de Renting. El cliente abona sólo los días utilizados, o reservará diferentes paquetes de días a usar y los repartirá según su conveniencia.',
    },{
      title: '¿Cuáles son las coberturas del vehículo de sustitución?',
      description: 'Deben ser las mismas que las del vehículo habitual, ya que el cliente no puede sufrir riesgos en los días en los que está haciendo uso del automóvil de sustitución.',
    },
    {
      title: '¿Puede conducir cualquier persona el vehículo de sustitución?',
      description: 'El vehículo de sustitución requiere de los mismos requisitos que el de renting.',
    },
    {
      title: '¿Puedo salir al extranjero con el vehículo de sustitución?',
      description: 'Tienes que consultar las condiciones de cesión del vehículo de sustitución que te han facilitado.',
    },
    {
      title: '¿Y si tengo un percance con el vehículo de sustitución y necesito una grúa?',
      description: 'Tendrás que ponerte en contacto con el departamento de siniestros de Ayvens (913 336 717) y ellos te indicarán como proceder.',
    },
    {
      title: '¿Qué es la Tarjeta VIP de Ayvens?',
      description: 'Es una tarjeta dirigida a todos los clientes que quieren ahorrar tiempo y despreocuparse contratando un servicio preferente; ampliando al máximo las prestaciones de su servicio de renting.',
    },
    {
      title: '¿Qué ventajas incluye la Tarjeta VIP de Ayvens?',
      description: '<ul>' +
        '<li>-Recogida y entrega del vehículo para cualquier operación que implique pasar por el taller, bien sea de mantenimiento o una revisión; cambio de neumáticos o reparación de lunas. Solo es necesario avisar donde quieres que se haga la recogida y la entrega.</li>\n' +
        '<li>-Línea exclusiva telefónica. Contarás con un número de teléfono exclusivo, impreso en la Tarjeta VIP, en el que podrás realizar todas las consultas que desees sin espera alguna.</li>\n' +
        '<li>-Vehículo de sustitución. Te proporcionamos un vehículo de sustitución cuando tu vehículo se encuentre inmovilizado debido a un siniestro, avería, mantenimiento prolongado o incluso un robo; hasta un máximo de diez días al año.</li>\n' +
        '<li>-Lavado después de cada operación de mantenimiento, reparación, cambio de neumáticos, etc.</li>\n' +
        '<li>-Tarjeta de carburante. Para pagar cómodamente los repostajes y los peajes controlando así consumos y gastos.</li>\n' +
        '<li>-Ampliación de la cobertura del conductor. En caso de sufrir un accidente, el conductor dispondrá de una indemnización adicional de 6.000 €.</li>\n</ul>',
    },
    {
      title: '¿Puedo contratar un renting de Ayvens en Canarias, Baleares, Ceuta o Melilla?',
      description: 'Por supuesto, entregamos vehículos fuera de la península. Consúltanos las condiciones, importes y disponibilidad.'
    }

]
})

export const getters = {

}

export const mutations = {

}

export const actions = {

}

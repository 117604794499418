var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.subtype !== "ald"
        ? _c("div", { staticClass: "rside" }, [
            _c(
              "p",
              { staticClass: "brand-model" },
              [
                _c("NuxtLink", { attrs: { to: "/ofertas/1" } }, [
                  _vm._v("Seat "),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "p",
              { staticClass: "brand-model" },
              [
                _c("NuxtLink", { attrs: { to: "/ofertas/1" } }, [
                  _vm._v("IBIZA "),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c("p", { staticClass: "from" }, [_vm._v("Desde")]),
            _vm._v(" "),
            _c("p", { staticClass: "price" }, [
              _vm.type === "particular" && _vm.subtype !== "redrive"
                ? _c("span", [_vm._v("282")])
                : _vm._e(),
              _vm._v(" "),
              _vm.type === "particular" && _vm.subtype === "redrive"
                ? _c("span", [_vm._v("300")])
                : _vm._e(),
              _vm._v(" "),
              _vm.type !== "particular"
                ? _c("span", [_vm._v("262")])
                : _vm._e(),
              _vm._v(" "),
              _c("span", { staticClass: "price-label" }, [
                _vm._v("€/mes"),
                _vm.subtype !== "redrive"
                  ? _c("span", [_vm._v("*")])
                  : _vm._e(),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "mt-2" }, [
              _c("img", {
                staticClass: "inline-block fuel-icon",
                attrs: {
                  src: require("assets/images/icons/fuel-icon.png"),
                  alt: "",
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "price-label fuel-text" }, [
                _vm.type === "particular" && _vm.subtype === "redrive"
                  ? _c("span", [_vm._v("Gasolina")])
                  : _c("span", [_vm._v("Gasolina")]),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.subtype !== "ald"
        ? _c("img", {
            staticClass: "home-car-img",
            attrs: {
              src: require("assets/images/home-car-desk-full.png"),
              alt: "",
            },
          })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "ul",
        { staticClass: "mt-5 pt-5 car-list" },
        _vm._l(_vm.info, function (item) {
          return _c("li", { staticClass: "mb-3 list-disc" }, [
            _vm._v(_vm._s(item)),
          ])
        }),
        0
      ),
      _vm._v(" "),
      _vm.seeInfo
        ? _c("NuxtLink", { attrs: { to: "/empresas/ayvens-flex" } }, [
            _c("button", { staticClass: "see-all more mb-3 mt-5" }, [
              _vm._v("Más información"),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      _vm.subtype !== "redrive"
        ? _c("p", { staticClass: "pb-5 pt-3" }, [
            _vm._v("*Oferta válida hasta fin de existencias"),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }